import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { getToken } from './Utils/Common';
import Layout from './User/Components/Layout/Layout';
import { useUser } from './Components/FullAccessContext/UserContext';
const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('./User/Login/Login'));
const VerifyEmail = lazy(() => import('./User/Login/VerifyEmail'));
const ResetPassword =lazy(()=>import('./User/Components/LoginModal/Components/ResetPassword'))
const SetNewPassword =lazy(()=>import('./User/Components/LoginModal/Components/SetNewPassword'))
const Dashboard = lazy(() => import('./User/Dashboard/Dashboard'));
const Products = lazy(() => import('./User/Products/Products'));
const APIKeys = lazy(() => import('./User/APIKeys/APIKeys'));
const APIUsage = lazy(() => import('./User/APIUsage/APIUsage'));
// const APIPricing = lazy(() => import('./User/APIPricing/APIPricing'));
const MyAccount = lazy(() => import('./User/MyAccount/MyAccount'));
const WhitelistIP = lazy(() => import('./User/WhitelistIP/WhitelistIP'))
const Logout = lazy(() => import('./User/Logout/Logout'));
const NotFound = lazy(() => import('./NotFound/NotFound'));
const UnauthorizedAccess =lazy(() => import("./User/Components/UnauthorizedAccess/UnauthorizedAccess"));
const CourtCaseCheck = lazy(() => import('./User/Products/CourtCaseCheck/CourtCaseCheck'));
const CourtCaseResponses = lazy(() => import('./User/Products/CourtCaseCheck/CourtCaseResponses'));
const NormalReport = lazy(() => import('./User/Products/CourtCaseCheck/NormalReport/NormalReport'));
const NormalReportDownload = lazy(() => import('./User/Products/CourtCaseCheck/NormalReport/NormalReportDownload'));
const ImmediateReport = lazy(() => import('./User/Products/CourtCaseCheck/ImmediateReport/ImmediateReport'));
const ImmediateReportDownload = lazy(() => import('./User/Products/CourtCaseCheck/ImmediateReport/ImmediateReportDownload'));
const DelayReport = lazy(() => import('./User/Products/CourtCaseCheck/DelayReport/DelayReport'));
const DelayReportDownload = lazy(() => import('./User/Products/CourtCaseCheck/DelayReport/DelayReportDownload'));
const BgcDashboard = lazy(() => import('./User/Products/TruBGC/Dashboard/Dashboard'));
const TruBGC = lazy(() => import('./User/Products/TruBGC/TruBGC'));
const TruBGCResponses = lazy(() => import('./User/Products/TruBGC/TruBGCResponses'));
const BGCDetail = lazy(() => import('./User/Products/TruBGC/TruBGCDetail'));
const TruBGCDownload = lazy(() => import('./User/Products/TruBGC/TruBGCDownload'));
const AcceptBGCRequest = lazy(() => import('./Verifications/BackgroundVerification/AcceptBGCRequest'));
const RejectBGCRequest = lazy(() => import('./Verifications/BackgroundVerification/RejectBGCRequest'));
const BackgroundVerification = lazy(() => import('./Verifications/BackgroundVerification/BackgroundVerification'));
const BGCAadhaar = lazy(() => import('./Verifications/BackgroundVerification/Components/BGCAadhaar'));
const EmployeeRating = lazy(() => import('./Verifications/BackgroundVerification/EmployeeRating'));
const EmployeeReject = lazy(() => import('./Verifications/BackgroundVerification/EmployeeReject'));
const Thankyou = lazy(() => import('./Verifications/BackgroundVerification/Thankyou'));
const TruAML = lazy(() => import('./User/Products/TruAML/TruAML'));
const TruAMLResponses = lazy(() => import('./User/Products/TruAML/TruAMLResponses'));
const AMLDetail = lazy(() => import('./User/Products/TruAML/TruAMLDetail'));
const TruAMLDownload = lazy(() => import('./User/Products/TruAML/TruAMLDownload'));
const TestApi = lazy(() => import('./User/TestAPIs/Sandbox'));
const TruBank = lazy(() => import('./User/Products/TruBank/TruBank'));
const TruBankResponses = lazy(() => import('./User/Products/TruBank/TruBankResponses'));
const TruBankDetail = lazy(() => import('./User/Products/TruBank/TruBankDetail'));
const TruBankReport = lazy(() => import('./User/Products/TruBank/TruBankReport'));
const TruBGV = lazy(() => import('./User/Products/TruBGV/TruBGV'));
const TruBGVResponses = lazy(() => import('./User/Products/TruBGV/TruBGVResponses'));
const TruBGVDetail = lazy(() => import('./User/Products/TruBGV/TruBGVDetail'));
const TruBGVDownload = lazy(() => import('./User/Products/TruBGV/TruBGVDownload'));
const TermsConditions = lazy(() => import('./User/Components/TermsConditions/TermsConditions'));
const TruBGVVerification = lazy(() => import('./User/Products/TruBGV/TruBGVVerification'));

export default function App(){
    const { showProduct } = useUser();
    
    const PrivateRouteWrapper = ({ element }) => {
        // Function to check if the user is authenticated
        const isUserAuthenticated = () => {
            if(getToken()){
                return true;
            } else {
                return false;
            }
        };
      
        // If the user is not authenticated, redirect to the login page
        if (!isUserAuthenticated()) {
            return <Navigate to="/login" />;
        }
      
        // Render the private route if the user is authenticated
        return element;
    };

    const PublicRouteWrapper = ({ element }) => {
        // Function to check if the user is authenticated
        const isUserAuthenticated = () => {
            if(getToken()){
                return true;
            } else {
                return false;
            }
        };
      
        // If the user is authenticated, redirect to the dashboard page
        if (isUserAuthenticated()) {
            return <Navigate to="/dashboard" />;
        }
      
        // Render the public route if the user is not authenticated
        return element;
    };

    const SuspenseComponent = () => {
        return(
            <div className="loader-container">
                <div className="loader-left"></div>
                <div className="loader-right">
                    <div className="loader-header">
                        <div className="large"></div>
                        <div className="large"></div>
                        <div className="small"></div>
                    </div>
                    <div className="loader-body"></div>
                </div>
            </div>
        )
    }
    return (
        <BrowserRouter>
            <Suspense fallback={
                <SuspenseComponent />
            }>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path="/login" element={<PublicRouteWrapper element={<Login />} />} />
                    <Route path='/verify-email/:token' element={<VerifyEmail />} />
                    <Route path='/reset-password-user/:token' element={<ResetPassword />} />
                    <Route path='/set-new-password/' element={<SetNewPassword/>} />
                    <Route path='/dashboard' element={<PrivateRouteWrapper element={<Layout><Dashboard /></Layout>}/> }/>
                    { 
                        showProduct
                        ?<Route path='/products' element={<PrivateRouteWrapper element={<Layout><Products /></Layout>} /> } />
                        :<Route path='/products' element={<PrivateRouteWrapper element={<Layout><UnauthorizedAccess customKey="product-disable"/></Layout>} /> } />
                    }
                    <Route path="/api-keys" element={<PrivateRouteWrapper element={<Layout><APIKeys /></Layout>} /> } />
                    <Route path="/api-usage" element={<PrivateRouteWrapper  element={<Layout><APIUsage /></Layout>} /> } />
                    {/* <Route path="/api-pricing" element={<Layout><APIPricing /></Layout>} /> */}
                    <Route path="/test-apis" element={<PrivateRouteWrapper element={<TestApi />} />} />
                    <Route path="/my-account"  element = {<PrivateRouteWrapper element={<Layout><MyAccount /></Layout>} />} />
                    <Route path="/whitelist-ip"  element = {<PrivateRouteWrapper element={<Layout><WhitelistIP /></Layout>} />} />
                    <Route path='/logout' element={<Logout />} />
                    {/* ===== Court case routes start here ===== */}
                    {
                        showProduct
                        ?<Route path="/court-case-check" element={<Layout><PrivateRouteWrapper element={<CourtCaseCheck />} /></Layout>} />
                        :<Route path="/court-case-check" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>}/></Layout>}  />
                    }
                    {
                        showProduct
                        ?<Route path="/court-case-responses" element={<Layout><PrivateRouteWrapper element={<CourtCaseResponses />} /></Layout>} />
                        :<Route path="/court-case-responses" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    <Route path='/normal-report' element={<Layout><PrivateRouteWrapper element={<NormalReport />} /></Layout>} />
                    <Route path='/normal-report-download/:id' element={<PrivateRouteWrapper element={<NormalReportDownload />} />} />
                    <Route path='/immediate-report' element={<Layout><PrivateRouteWrapper element={<ImmediateReport />} /></Layout>} />
                    <Route path='/immediate-report-download/:id' element={<PrivateRouteWrapper element={<ImmediateReportDownload />} />} />
                    <Route path='/delay-report' element={<Layout><PrivateRouteWrapper element={<DelayReport />} /></Layout>} />
                    <Route path='/delay-report-download/:id' element={<PrivateRouteWrapper element={<DelayReportDownload />} />} />
                    {/* ===== Court case routes end here ===== */}
                    
                    {/* ===== TruBGC routes start here ===== */}
                    {
                        showProduct
                        ?<Route path='/bgc' element={<Layout><PrivateRouteWrapper element={<TruBGC />} /></Layout>} />
                        :<Route path='/bgc' element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    <Route path='/bgc-dashboard' element={<Layout><PrivateRouteWrapper element={<BgcDashboard />} /></Layout>} />
                    {
                        showProduct
                        ?<Route path='/bgc-responses' element={<Layout><PrivateRouteWrapper element={<TruBGCResponses />} /></Layout>} />
                        :<Route path='/bgc-responses' element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    <Route path='/bgc-detail' element={<Layout><PrivateRouteWrapper element={<BGCDetail />} /></Layout>} />
                    <Route path="/bgc-report/:id" element={<TruBGCDownload />} />
                    <Route path="/accept-bgc-request/:code" element={<AcceptBGCRequest />} />
                    <Route path="/reject-bgc-request/:code" element={<RejectBGCRequest />} />
                    <Route path="/background-verification/:step" element={<BackgroundVerification />} />
                    <Route path="/bgc-aadhaar/:code" element={<BGCAadhaar />} />
                    <Route path='/employee-rating/:encryptedUrl' element={<EmployeeRating />} />
                    <Route path='/employee-reject/:encryptedUrl' element={<EmployeeReject />} />
                    <Route path='/thankyou' element={<Thankyou />} />
                    {/* ===== TruBGC routes end here ===== */}
                    
                    {/* ===== TruAML routes start here ===== */}
                    {  
                        showProduct
                        ?<Route path="/aml" element={<Layout><PrivateRouteWrapper element={<TruAML />} /></Layout>} />
                        :<Route path="/aml" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    {
                        showProduct
                        ?<Route path="/aml-responses" element={<Layout><PrivateRouteWrapper element={<TruAMLResponses />} /></Layout>} />
                        :<Route path="/aml-responses" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    {
                        showProduct
                        ?<Route path="/aml-detail" element={<Layout><PrivateRouteWrapper element={<AMLDetail />} /></Layout>} />
                        :<Route path="/aml-detail" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    <Route path="/aml-download/:id" element={<PrivateRouteWrapper element={<TruAMLDownload />} />} />
                    {/* ===== TruAML routes end here ===== */}
                    
                    {/* ===== TruBank routes start here ===== */}
                    {
                        showProduct
                        ?<Route path="/trubank" element={<Layout><PrivateRouteWrapper element={<TruBank />} /></Layout>} />
                        :<Route path="/trubank" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    {
                        showProduct
                        ?<Route path="/trubank-responses" element={<Layout><PrivateRouteWrapper element={<TruBankResponses />} /></Layout>} />
                        :<Route path="/trubank-responses" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    {
                        showProduct
                        ?<Route path="/trubank-detail" element={<Layout><PrivateRouteWrapper element={<TruBankDetail />} /></Layout>} />
                        :<Route path="/trubank-detail" element={<Layout><PrivateRouteWrapper element={<UnauthorizedAccess customKey="product-disable"/>} /></Layout>} />
                    }
                    <Route path="/trubank-report/:id" element={<PrivateRouteWrapper element={<TruBankReport />} />} />
                    {/* ===== TruBank routes end here ===== */}
                    
                    {/* ===== TruBGV new design routes start here ===== */}
                    <Route path="/trubgv" element={<Layout><PrivateRouteWrapper element={<TruBGV />} /></Layout>} />
                    <Route path="/trubgv-responses" element={<Layout><PrivateRouteWrapper element={<TruBGVResponses />} /></Layout>} />
                    <Route path="/trubgv-detail" element={<Layout><PrivateRouteWrapper element={<TruBGVDetail />} /></Layout>} />
                    <Route path="/trubgv-download" element={<PrivateRouteWrapper element={<TruBGVDownload />} />} />
                    <Route path="/trubgv-verification" element={<Layout><PrivateRouteWrapper element={<TruBGVVerification />} /></Layout>} />
                    {/* ===== TruBGV new design routes end here ===== */}

                    {/* ===== Terms and conditions ===== */}
                    <Route path="/terms-conditions" element={<TermsConditions/>} />

                    {/* ===== Page not found ===== */}
                    <Route element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}