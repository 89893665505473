import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ColorProvider } from './Components/ColorContext/ColorContext';
import { UserProvider } from './Components/FullAccessContext/UserContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ColorProvider>
        <UserProvider>
            <App />
        </UserProvider>
    </ColorProvider>
);
