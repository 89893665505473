import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import oceanapi, {
  getUserName,
  userRegistration,
} from "../../../../Base/OceanConfig";
import Message from "../../../../Components/Message/Message";
import { ColorContext } from "../../../../Components/ColorContext/ColorContext";
import styles from "../login-modal.module.css";

export default function SignUp(props) {
  const { domain } = useContext(ColorContext);
  const { css } = useContext(ColorContext);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [ extraction, setExtraction ] = useState(false);
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");

  const fetchUserName = () => {
    oceanapi
      .get(getUserName + domain)
      .then((response) => {
        setLoading(false);
        if (response.data.code === 200) {
          setUserName(response.data.result);
        } else {
          setUserName("");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchUserName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerFunction = () => {
    setLoading(true);
    oceanapi
      .post(userRegistration, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phone,
        userName: userName,
        password: password,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.code === 200) {
          setExtraction(true);
          setMessageClass("success");
          setMessage(res.data.message);
          setTimeout(() => {
            setMessage("");
          }, 2000);
        } else {
          setMessageClass("error");
          setMessage(res.data.message);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  function Validate(e) {
        e.preventDefault();
        //letters for Valid Characters i.e. Alphabets, Numbers and Space.
        var letters = /^[A-Za-z ]+$/
        var numbers = /^[0-9]+$/

        if(!firstName.match(letters)) {
            setMessage('No special character & numbers allowed in First name');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else if (!lastName.match(letters)) {
            setMessage('No special character & numbers allowed in Last name');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else if(!phone.match(numbers)){
            setMessage('No special character & alphabets allowed in Phone number.');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else {
          registerFunction();
        }
    }

  const closeMessage = () => {
    setMessage("");
  };

  const closeModal = () => {
    setExtraction(false);
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setPassword('');
}

  return (
    <>
      {message && (
        <Message
          message={message}
          messageClass={messageClass}
          closeMessage={closeMessage}
        />
      )}
      {extraction &&
            <div className={styles['custom-container']}>
                <div className={styles['custom-background']} onClick={() => closeModal()}></div>
                <div className={styles['pop-up-form-custom']}>
                    <img src='./images/verify-email.png' alt="extraction" />
                    <h4>Verify email !</h4>
                    <p>We will send you a link to verify your email address, click on it to confirm </p>
                </div>
            </div>
        }
      <div
        className={styles[props.page === "login-page" ? "login-page-content" : "registration-modal-content"]}>
        <form onSubmit={Validate}>
          <label>First name</label>
          <input   style={css.borderLeftColor} type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Enter first name"  required />
          <label>Last name</label>
          <input  style={css.borderLeftColor} type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Enter last name" required />
          <label>Email</label>
          <input  style={css.borderLeftColor} type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email" required/>
          <label>Phone number</label>
          <input  style={css.borderLeftColor} type="text" minLength="10" maxLength="10" placeholder="Enter phone number" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
          <label>Password</label>
          <input  style={css.borderLeftColor} type="password" autocomplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" required/>
          <input type="checkbox" required />{" "}
          <span className={styles["terms-and-conditions"]}>
            I agree to{" "} <Link style={css.color} to="https://invincibleocean.com/terms-cancellation-policy/" target="_blank"> Terms & Conditions </Link>
          </span>
          {loading ? (
            <button
              className="btn primary-button w-100 mt-3"
              style={css.primaryButton}
              disabled
            >
              {" "}
              Wait ...{" "}
            </button>
          ) : (
            <button
              type="submit"
              className="btn primary-button w-100 mt-3"
              style={css.primaryButton}
            >
              {" "}
              Submit
            </button>
          )}
        </form>
        <p className={styles["have-account"]}>
          Already have an account?{" "}
          <span style={css.color} onClick={() => props.toggleRegistration(false)}>
            Sign In
          </span>
        </p>
      </div>
    </>
  );
}
