import React, { useContext, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import oceanapi, { fetchRazorpayKey, createOrderUrl, generateSignature, verifySignature, verifyProductSignature } from '../../../Base/OceanConfig';
import { ColorContext } from '../../../Components/ColorContext/ColorContext';
import { getToken, getUserName } from '../../../Utils/Common';
import Message from '../../../Components/Message/Message';
import styles from './navbar.module.css';

export default function Razorpay(props){
    var userId = props.userId;
    const token = getToken();
    const userName = getUserName();
    const { domain } = useContext(ColorContext);
    const { css } = useContext(ColorContext);
    const [ price, setPrice ] = useState(props.walletName === "API" ? 2000 : 500);
    const [ gst, setGst ] = useState(props.walletName === "API" ? 360 : 90);
    const [ afterGstPrice, setAfterGstPrice ] = useState(props.walletName === "API" ? 2360 : 590);
    const [ loadingDetails, setLoadingDetails ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ messageClass, setMessageClass ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ razorpayDetails, setRazorpayDetails ] = useState('');

    const config = {
        headers: {
            'token': token
        }
    }

    useEffect(() => {
        fetchKey();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchKey = () => {
        oceanapi.get(fetchRazorpayKey + domain, config)
        .then(res => {
            setLoadingDetails(false);
            if(res.data.code === 200){
                calculation(props.walletName === "API" ? res.data.result.minimumApiAmount : res.data.result.minimumProductAmount);
                setRazorpayDetails(res.data.result);
            } else {
                setRazorpayDetails('');
            }
        })
        .catch(err => {
            console.error(err);
            setLoadingDetails(false);
            setMessage('Something went wrong. Try again.');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('')
            }, 2000);
        })
    }

    function decryptFunction(text) {
        const textParts = text.split(':');
        const iv = CryptoJS.enc.Hex.parse(textParts.shift());
        const encryptedText = textParts.join(':');
        const decrypted = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY), {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    const loadScript = (src) => {
        return new Promise ((resolve) => {
            const script = document.createElement('script')
            script.src = src

            script.onload = () => {
                resolve(true)
            }

            script.onerror = () => {
                resolve(false)
            }

            document.body.appendChild(script)
        })
    }

    const createOrder = (e) => {
        e.preventDefault();
        setLoading(true);
        if(price >= (props.walletName === "API" ? razorpayDetails.minimumApiAmount : razorpayDetails.minimumProductAmount)){
            oceanapi.post(createOrderUrl, {
                subdomain: domain,
                userName: userName,
                amount: price,
                walletName: props.walletName === "API" ? 'api' : 'product'
            }, config)
            .then((res) => {
                if(res.data.code === 200){
                    setLoading(false);
                    displayRazorpay(res.data.result.id);
                } else {
                    setLoading(false);
                    setMessage(res.data.message);
                    setMessageClass('error');
                    setTimeout(() => {
                        setMessage('')
                    }, 2000);
                }
            })
            .catch((error) => {
                console.error(error);
            })
        } else {
            setLoading(false);
            setMessage(`Amount should be more than or equal to ${props.walletName === "API" ? razorpayDetails.minimumApiAmount : razorpayDetails.minimumProductAmount}`);
            setMessageClass('error');
            setTimeout(() => {
                setMessage('')
            }, 2000);
        }
    }
    
    const displayRazorpay = async (orderId) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    
        if(!res){
            alert('You are offline')
            return
        }

        const options = {
            key: decryptFunction(razorpayDetails.razorpayKeyId),
            currency: "INR",
            amount: afterGstPrice * 100,
            name: razorpayDetails.paymentName,
            description: razorpayDetails.paymentDescription,
            image: razorpayDetails.paymentLogo,

            handler: function (response) {
                generateSignatureFunction(orderId, response.razorpay_payment_id);
            },
            prefill: {
                name: "API KYC Solution"
            }
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    const generateSignatureFunction = (orderId, paymentId) => {
        oceanapi.post(generateSignature, {
            userId: userId,
            order_id: orderId,
            payment_id: paymentId,
            subdomain: domain,
            userName: userName
        }, config)
        .then((res) => {
            if(res.data.code === 200){
                setMessage(res.data.message);
                setMessageClass('success');
                if(props.walletName === 'Product'){
                    verifyProductSignatureFunction(orderId, paymentId, res.data.result.expectedSignature);
                } else if(props.walletName === 'API') {
                    verifySignatureFunction(orderId, paymentId, res.data.result.expectedSignature);
                }
                setTimeout(() => {
                    setMessage('');
                }, 1000);
            } else {
                setMessage(res.data.message);
                setMessageClass('error');
                setTimeout(() => {
                    setMessage('')
                }, 2000);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const verifySignatureFunction = (orderId, paymentId, signature) => {
        oceanapi.post(verifySignature, {
            userId: userId,
            order_id: orderId,
            payment_id: paymentId,
            signature: signature,
            subdomain: domain,
            userName: userName
        }, config)
        .then((res) => {
            if(res.data.code === 200){
                setMessage(res.data.message);
                setMessageClass('success');
                props.getCredits();
                props.closeRazorpayModal();
                setTimeout(() => {
                    setMessage('');
                }, 1000);
            } else {
                setMessage(res.data.message);
                setMessageClass('error');
                setTimeout(() => {
                    setMessage('')
                }, 2000);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const verifyProductSignatureFunction = (orderId, paymentId, signature) => {
        oceanapi.post(verifyProductSignature, {
            userId: userId,
            order_id: orderId,
            payment_id: paymentId,
            signature: signature,
            subdomain: domain,
            userName: userName
        }, config)
        .then((res) => {
            if(res.data.code === 200){
                setMessage(res.data.message);
                setMessageClass('success');
                props.getProductCredits();
                props.closeRazorpayModal();
                setTimeout(() => {
                    setMessage('');
                }, 1000);
            } else {
                setMessage(res.data.message);
                setMessageClass('error');
                setTimeout(() => {
                    setMessage('')
                }, 2000);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const calculation = (value) => {
        setPrice(value);
        const amount = parseInt(value);
        if(value.length === 0){
            setGst(0);
            setAfterGstPrice(0);
        } else {
            setGst(Math.round(amount * 0.18));
            setAfterGstPrice(Number(amount + Math.round(amount * 0.18)));
        }
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        <div className={styles['razorpay-container']}>
            <div className={styles['razorpay-background']} onClick={() => props.closeRazorpayModal()}></div>
            <div className={styles['razorpay']}>
                <div>
                    <img src="images/add-balance.svg" alt="add-balance" />
                </div>
                <div>
                    <h3>Add {props.walletName} Balance <img src="images/times-circle.svg" alt="times" onClick={() => props.closeRazorpayModal()} /></h3>
                    <hr/>
                    {
                        loadingDetails
                        ?<div className={styles['razorpay-loader']}>
                            <span className="fa fa-refresh fa-spin"></span>
                            <p>Fetching details</p>
                        </div>
                        :<form onSubmit={createOrder}>
                            <label>Enter amount</label>
                            <input type="number" min={props.walletName === "API" ? razorpayDetails.minimumApiAmount : razorpayDetails.minimumProductAmount} placeholder="Enter amount" value={price} onChange={e => calculation(e.target.value)} required/>
                            <label>GST (18%)</label>
                            <input type="text" value={gst} disabled/>
                            <label>Final amount</label>
                            <input type="text" value={afterGstPrice} disabled/>
                            {
                                razorpayDetails
                                ?<button type="submit" className="btn primary-button mt-3 px-5" style={css.primaryButton}>{loading?<><i className="fa fa-circle-o-notch fa-spin"></i> Wait ...</>:'Add Balance'}</button>
                                :<>
                                    <p className={styles['razorpay-error']}>Can't proceed for adding balance. Please contact admin.</p>
                                    <button type="submit" className="btn primary-button mt-3 px-5" style={css.primaryButton} disabled>Add Balance</button>
                                </>
                            }
                        </form>
                    }
                </div>
            </div>
        </div>
    </>);
}
