import React, { createContext, useState, useEffect } from 'react';
import oceanapi, { fetchThemeDetails, signupCustomization } from '../../Base/OceanConfig';
const ColorContext = createContext();

const ColorProvider = ({ children }) => {
    // const domain = window.location.host === '192.168.0.25:3000'?'userdashboard.dev.invincibleocean.io':window.location.host;
    const domain = window.location.host === 'localhost:3000' ? 'userdashboard.dev.invincibleocean.io' : window.location.host;
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState('');
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [status, setStatus] = useState(true);
    const [showForgotPassword, setShowForgotPassword] = useState(true);
    const [createAccount, setCreateAccount] = useState(true);
    const [copyRight, setCopyRight] = useState('');
    const [termCondition, setTermCondition] = useState('');

	const themeFunction = () => {
		oceanapi.get(fetchThemeDetails + domain)
		.then(res => {
			if(res.data.code === 200){
				setLoading(false);
                document.title = res.data.result.title?res.data.result.title:'API KYC Solution';
                const description = res.data.result.description?res.data.result.description:'API KYC Solution';
                const metaDescription = document.querySelector('meta[name="description"]');
                if(metaDescription) {
                    metaDescription.setAttribute('content', description); // Update the meta description tag
                }
                // Get FavIcon here: 
                const favIcon = document.querySelector("link[rel~='icon']");
                favIcon.href = res.data.result.favIcon;
				setLogo(res.data.result.logo);
                setSecondaryColor(res.data.result.theme.secondColor);
				setPrimaryColor(res.data.result.theme.primaryColor);
			}
		})
		.catch(err => {
			console.error(err);
		})
	}

    const signUpCustomization = () =>{
        oceanapi.get(signupCustomization + domain)
        .then(res =>{
            if(res.data.code === 200){
                setLoading(false);  
                setStatus(res.data.result.status);
                setTermCondition(res.data.result.termCondition);
                setShowForgotPassword(res.data.result.forgotPass);
                setCreateAccount(res.data.result.createAccount);
                setCopyRight(res.data.result.copyRight);
            }
        })
        .catch(err => {
			console.error(err);
		})
    }

	useEffect(() => {
        signUpCustomization();
		themeFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const css = {
        color: {
            color: primaryColor
        },
        secondaryColor: {
            color: secondaryColor
        },
        borderLeftColor: {
            borderLeftColor: primaryColor
        },
        border: {
            border:`1px solid ${primaryColor}`
        },
        secondaryBorder: {
            border:`1px solid ${secondaryColor}`
        },
        primaryButton: {
            border: `1px solid ${primaryColor}`,
            backgroundColor: `${primaryColor}`
        },
        secondaryButton: {
            border: `1px solid ${primaryColor}`,
            color: primaryColor
        },
        lightBackground: {
            border: `1px dashed ${primaryColor}`,
            background: `${primaryColor}1c`,
            color: primaryColor
        },
        gradientBackground: {
            background: `linear-gradient(90deg, ${primaryColor}, ${primaryColor}30)`
        },
        backgroundLight: {
            color: primaryColor,
            backgroundColor: `${primaryColor}1A`
        },
        backgroundColor: {
            backgroundColor: primaryColor
        },
        generateKeyBox: {
            border: `2px dashed ${primaryColor}`,
            backgroundColor: `${primaryColor}1A`
        },
        myAccountSelected: {
            borderRight: `5px solid ${primaryColor}`,
            color: primaryColor
        },
        active: {
            borderBottom: `1px solid ${secondaryColor}`,
            color: secondaryColor
        },
        graphActive: {
            border: `1px solid ${primaryColor}`,
            background: primaryColor,
            color: '#FFFFFF'
        },
        bgcdetails: {
            color: secondaryColor
        },
        stepdetails: {
            backgroundColor: secondaryColor,
            color: '#FFF'
        }
    };

    return (
        <ColorContext.Provider value={{ logo, primaryColor, domain, loading, css, status, createAccount, copyRight, termCondition, showForgotPassword }}>
            {children}
        </ColorContext.Provider>
    );
};

export { ColorContext, ColorProvider };