import React, { useState } from 'react';
import oceanapi, { getUserName, oceanDemoQuery } from '../../../Base/OceanConfig';
import Message from '../../../Components/Message/Message';
import styles from './navbar.module.css';

export default function Democall(props){
	const css = props.css;
	const domain = props.domain;
	const logo = props.logo;
    const [ demoModal, setDemoModal ] = useState(false);
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ companyName, setCompanyName ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    function validate(e) {
        e.preventDefault();
        //letters for Valid Characters i.e. Alphabets, Numbers and Space.
        var letters = /^[A-Za-z ]+$/
        var numbers = /^[0-9]+$/

        if(!firstName.match(letters)) {
            setMessage('Special characters and numbers are not allowed in first name.');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else if(!lastName.match(letters)) {
            setMessage('Special characters and numbers are not allowed in last name.');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else if(!phone.match(numbers)) {
            setMessage('Special characters and alphabets are not allowed in phone number.');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        } else {
            fetchUser();
        }
    }

    const fetchUser = () => {
        setLoading(true);
        oceanapi.get(getUserName + domain)
        .then((res) => {
            if(res.data.code === 200){
                sendRequest(res.data.result);
            } else {
                setLoading(false);
                setMessage(res.data.message);
                setMessageClass('error');
                setTimeout(() => {
                    setMessage('');
                }, 10000)
            }
        }).catch((error) => {
            console.error(error);
            setLoading(false);
            setMessage('Something went wrong. Try again.');
            setMessageClass('error');
            setTimeout(() => {
                setMessage('');
            }, 10000)
        })
    }

    const sendRequest = (userName) => {
        setLoading(true);
        oceanapi.post(oceanDemoQuery, {
            parentName: userName,
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobile: phone,
            companyName: companyName
        })
        .then((res) => {
            if(res.data.code === 200){
                setLoading(false);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setCompanyName('');
                setDemoModal(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setLoading(false);
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong. try again later")
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const closeMessage = () => { setMessage('') }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={closeMessage}/>}
        {demoModal &&
            <div className={styles['demo-call-container']}>
                <div className={styles['demo-call-background']} onClick={() => setDemoModal(false)}></div>
                <div className={styles["demo-call-page"]}> 
                    <div className={styles["demo-call-left-side"]}>
                        <div>
                            <img src={logo} alt="democall-icon" />
                            <p>Just answers few questions, so we can personalize the right experience for you</p>
                        </div>
                        <img src="./images/demo-call.svg" alt="invincibleocean" />
                    </div>
                    <div className={styles["demo-call-right-side"]}>
                        <h3>Schedule a demo call <img src="./images/times-circle-color.svg" alt="times-circle" onClick={() => setDemoModal(false)} /></h3>
                        <div>
                            <form onSubmit={validate}>
                                <label>First name</label>
                                <input type="text" placeholder="Enter first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                                <label>Last name</label>
                                <input type="text" placeholder="Enter last name" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
                                <label>E-mail</label>
                                <input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                <label>Phone number</label>
                                <input type="tel" minLength="10" maxLength="10" placeholder="Enter phone number" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                                <label>Company name (optional)</label>
                                <input type="text" placeholder="Enter company name" value={companyName} onChange={(e) => setCompanyName(e.target.value)}/>
                                <button className="btn primary-button w-100 mt-3" style={css.primaryButton}>{loading?'Sending ...':'Request a demo call'}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        }
        <button className="btn secondary-button" style={css.secondaryButton} onClick={() => setDemoModal(true)}><i className="fa fa-phone"></i> Book a demo call</button>
    </>)
}