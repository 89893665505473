import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getToken } from '../../../Utils/Common';
import { useUser } from '../../../Components/FullAccessContext/UserContext';
import oceanapi, { getUserName, fetchUsers, fetchCredits, fetchProductCredits } from '../../../Base/OceanConfig';
import Message from '../../../Components/Message/Message';
import LoginModal from '../LoginModal/LoginModal';
import Democall from './Democall';
import Razorpay from './Razorpay';
import styles from './navbar.module.css';

export default function Navbar(props) {
    const location = useLocation().pathname;
    const token = getToken();
    const domain = props.domain;
    const userId = props.userId;
	const css = props.css;
	const logo = props.logo;
    const [ userDetails, setUserDetails ] = useState('');
    const [ loginBonus, setLoginBonus ] = useState(false);
    const [ loginModal, setLoginModal ] = useState(false);
    const [ userCredits, setUserCredits ] = useState(0);
    const [ productCredits, setProductCredits ] = useState(0);
    const [ firstLogin, setFirstLogin ] = useState(false);
    const [ userDropdown, setUserDropdown ] = useState(false);
    const [ apiCreditLoader, setApiCreditLoader ] = useState(false);
    const [ productCreditLoader, setProductCreditLoader ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ walletName, setWalletName ] = useState('');
    const [ razorpayModal, setRazorpayModal ] = useState(false);
    const { fullAccess, setFullAccess } = useUser('');
   
    const config = {
        headers: {
            'token': token
        }
    }

    const closeModal = () => { setLoginModal(false) }

    const fetchUser = () => {
        oceanapi.get(getUserName + domain)
        .then(res => {
            if(res.data.code === 200){
                getUserDetails(res.data.result);
            } else {
                window.location.replace('/logout');
            }
        })
        .catch(err => {
            console.error(err);
            window.location.replace('/logout');
        })
    }

    const getUserDetails = (a) => {
        oceanapi.get(fetchUsers + a + '/' + userId)
        .then((res) => {
            if(res.data.code === 200){
                if(res.data.result.isActive === true){
                    setUserDetails(res.data.result);
                    setFullAccess(res.data.result.fullAccess);
                } else {
                    window.location.replace('/logout');
                }
            } else {
                window.location.replace('/logout');
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    const getCredits = (x) => {
        if(x){
            setApiCreditLoader(true);
        } else {
            setLoading(true);
        }
        oceanapi.get(fetchCredits + userId, config)
        .then((res) => {
            setApiCreditLoader(false);
            setLoading(false);
            if(res.data.code === 200){
                setUserCredits(res.data.response.amount?res.data.response.amount:0);
                if(x){
                    setMessageClass('success');
                    setMessage("API wallet refreshed");
                    setTimeout(() => {
                        setMessage('')
                    }, 5000);
                }
            } else {
                setUserCredits(0);
            }
        }).catch((error) => {
            console.error(error);
            if(error.response.data.code === 401){
                window.location.replace('/logout');
            }
            setApiCreditLoader(false);
            setLoading(false);
        })
    }

    const getProductCredits = (x) => {
        if(x){
            setProductCreditLoader(true);
        } else {
            setLoading(true);
        }
        oceanapi.get(fetchProductCredits + userId, config)
        .then((res) => {
            setProductCreditLoader(false);
            setLoading(false);
            if(res.data.code === 200){
                setProductCredits(res.data.response.amount?res.data.response.amount:0);
                if(x){
                    setMessageClass('success');
                    setMessage("Product wallet refreshed");
                    setTimeout(() => {
                        setMessage('')
                    }, 5000);
                }
            } else {
                setProductCredits(0);
            }
        }).catch((error) => {
            console.error(error);
            setProductCreditLoader(false);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(userId){
            fetchUser();
            getCredits();
            getProductCredits();
            if(sessionStorage.getItem("firstLogin")){
                setFirstLogin(true);
                sessionStorage.removeItem("firstLogin", true);
            }
        } else {
            openLoginBonus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openLoginBonus = () => {
        if(!sessionStorage.getItem("user")){
            setTimeout(() => {
                setLoginBonus(true);
                sessionStorage.setItem("user", 1);
            }, 5000);
        }
    }

    const openLoginModal = () => {
        setLoginModal(true);
        setLoginBonus(false);
    }

    const openMenu = () => {
        setTimeout(() => {
            setUserDropdown(true);
        }, 100);
    }

    const closeMenu = () => {
        setTimeout(() => {
            setUserDropdown(false);
        }, 500);
    }

    const closeMessage = () => { setMessage('') }

    const openRazorpay = (x) => {
        setWalletName(x);
        setRazorpayModal(true);
    }

    const closeRazorpayModal = () => {
        setRazorpayModal(false);
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={closeMessage} />}
        {razorpayModal && <Razorpay userId={userId} getCredits={getCredits} getProductCredits={getProductCredits} walletName={walletName} closeRazorpayModal={closeRazorpayModal}/>}
        {loginBonus && <div className={styles['login-bonus-container']}>
            <div className={styles['login-bonus-background']} onClick={() => setLoginBonus(false)}></div>
            <div className={styles['login-bonus']}>
                <span >New!</span>
                <img src="images/times-circle-color.svg" alt="times" onClick={() => setLoginBonus(false)} />
                <div>
                    <h5>Sign Up Bonus!</h5>
                    <p>Free ₹20 credits to test APIs, Do Sign Up & grab it now!</p>
                </div>
                <div>
                    <button className="btn primary-button px-5" style={css.primaryButton} onClick={() => openLoginModal()}>Sign Up</button>
                </div>
            </div>
        </div>}
        {firstLogin && <div className={styles['bonus-credit-container']}>
            <div className={styles['bonus-credit-background']} onClick={() => setFirstLogin(false)}></div>
            <div className={styles['bonus-credit-box']}>
                <img src="images/times-circle-color.svg" alt="times" onClick={() => setFirstLogin(false)} />
                <img src="images/bonus-credit.svg" alt="bonus-credit" className="w-100" />
                <div>
                    <h5>Bonus Credit!</h5>
                    <p>Congratulations, You get <span>bonus amount</span> on successful creation of account.</p>
                    <div className={styles['credit-amount']}>₹20</div>
                    <button className="btn primary-button px-5" style={css.primaryButton} onClick={() => setFirstLogin(false)}>Continue</button>
                </div>
            </div>
        </div>}
        {loginModal && <LoginModal redirectPath={location} closeModal={closeModal}/>}
        <div className={styles["navbar-container"]}>
            {userId
                ?<h4 className={styles['user-detail']}>Welcome, <span>{userDetails.firstName}!</span></h4>
                :<div className={styles["navbar-menu"]}>
                    <span>1. Choose Template</span>
                    <span>2. Create Journey</span>
                    <span>3. Plan Delivery</span>
                </div>
            }
            <div className={styles["navbar-action"]}>
                {fullAccess && <Democall domain={domain} css={css} logo={logo}/>}
                {userId
                    ?<>
                        <div style={{display:'grid',gridTemplateColumns:'1fr 1fr'}}>
                            {fullAccess && <div className={styles["wallet-card"]} style={css.border}>
                                <div className={styles["add-balance"]} onClick={() => openRazorpay('Product')} style={css.backgroundColor}>
                                    <p>+</p>
                                    <p>ADD</p>
                                </div>
                                <div className={styles["wallet-balance"]}>
                                    <span className={`fa fa-refresh ${productCreditLoader && 'fa-spin'}`} onClick={() => getProductCredits(true)}></span>
                                    <h6>Product Wallet</h6>
                                    <h5>₹{loading?"__":<>{productCredits.toLocaleString('en-IN')}</>}</h5>
                                </div>
                            </div>}
                            {fullAccess && <div className={styles["wallet-card"]} style={css.border}>
                                <div className={styles["add-balance"]} onClick={() => openRazorpay('API')} style={css.backgroundColor}>
                                    <p>+</p>
                                    <p>ADD</p>
                                </div>
                                <div className={styles["wallet-balance"]}>
                                    <span className={`fa fa-refresh ${apiCreditLoader && 'fa-spin'}`} onClick={() => getCredits(true)}></span>
                                    <h6>API Wallet</h6>
                                    <h5>₹{loading?"__":<>{userCredits.toLocaleString('en-IN')}</>}</h5>
                                </div>
                            </div>}
                        </div>
                        <div onMouseEnter={() => openMenu()} onMouseLeave={() => closeMenu()}>
                            <img src="./images/user-icon.svg" alt="icon" />
                            {userDropdown && <div className={styles['user-dropdown']}>
                                <img src="images/triangle.png" alt="triangle" />
                                <span>Logged in as</span>
                                <h5>
                                    {userDetails.firstName} {userDetails.lastName}
                                    <span>{userDetails.email}</span>
                                </h5>
                                <p><span><a href="/my-account"><i className="fa fa-user-o mr-1"></i> My account</a></span></p>
                                <div>
                                    <Link to="/logout"><button className="btn primary-button w-100" style={css.primaryButton}>Logout</button></Link>
                                </div>
                            </div>}
                        </div>
                    </>
                    :<button className="btn secondary-button ml-2" style={css.secondaryButton} onClick={() => setLoginModal(true)}>Sign in/up</button>
                }
            </div>
            <div className={styles["mobile-navbar-action"]}>
                <Democall domain={domain} css={css} logo={logo}/>
                {userId
                    ?<>
                        <div className={styles['mobile-wallet-container']}>
                            <div className={styles["wallet-card"]} style={css.border}>
                                <div className={styles["add-balance"]} onClick={() => openRazorpay('Product')} style={css.backgroundColor}>
                                    <p>+</p>
                                    <p>ADD</p>
                                </div>
                                <div className={styles["wallet-balance"]}>
                                    <span className={`fa fa-refresh ${productCreditLoader && 'fa-spin'}`} onClick={() => getProductCredits(true)}></span>
                                    <h6>Product Wallet</h6>
                                    <h5>₹{loading?"__":<>{productCredits.toLocaleString('en-IN')}</>}</h5>
                                </div>
                            </div>
                            <div className={styles["wallet-card"]} style={css.border}>
                                <div className={styles["add-balance"]} onClick={() => openRazorpay('API')} style={css.backgroundColor}>
                                    <p>+</p>
                                    <p>ADD</p>
                                </div>
                                <div className={styles["wallet-balance"]}>
                                    <span className={`fa fa-refresh ${apiCreditLoader && 'fa-spin'}`} onClick={() => getCredits(true)}></span>
                                    <h6>API Wallet</h6>
                                    <h5>₹{loading?"__":<>{userCredits.toLocaleString('en-IN')}</>}</h5>
                                </div>
                            </div>
                        </div>
                    </>
                    :<button className="btn secondary-button ml-2" style={css.secondaryButton} onClick={() => setLoginModal(true)}>Sign in/up</button>
                }
            </div>
        </div>
    </>);
}