import jwt_decode from "jwt-decode";

export const getUser = () => {
    var token = getToken();
    var userId = token?jwt_decode(token).id:null;
    return userId;
}

export const getUserName = () => {
    var token = getToken();
    var userName = token?jwt_decode(token).userName:null;
    return userName;
}

export const getToken = () => {
    return localStorage.getItem("token") || null;
}

export const setSession = (token) => {
    localStorage.setItem("token", token);
}

export const removeSession = () => {
    localStorage.removeItem("token");
}

