import axios from "axios";
export const baseurl = process.env.REACT_APP_WHITELABEL_URL;

const oceanapi = axios.create({
    baseURL: baseurl,
    headers: {
        'token': process.env.REACT_APP_TOKEN,
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

export default oceanapi;

// User login
export const userLogin = "/whitelistUserLogin";

// User registration
export const userRegistration = "/whiteLevelUserRegistration";

// User email verification
export const userEmailVerification = "/user-email-verification/";

// Fetch user name from domain
export const getUserName = "/getUserName/";

// Fetch user details
export const fetchUsers = "/admin/whitelistUserByUserId/";

// Create AML request
export const amlVerification = "/amlVerification";

// fetch list of aml verifications done by a user
export const amlVerificationData = "/amlList/";

// fetch details of a aml verification
export const getAmlVerification = "/getAmlVerification/";

// Get Client ID and Secret Key
export const getSecretKeyUrl = "/getProductsKeys/";

// Create Client ID and Secret Key
export const createSecretKeyUrl = "/generateProducts/keys";

// Delete Client ID and Secret Key
export const deleteSecretKeyUrl = "/user/deleteKey/";

// Fetch API logs of user
export const fetchApiLogs = "/user/getUserTransactions/";

// Fetch API transations of user
export const fetchApiTransactions = "/getUserApiTransactions/";

// Fetch product transations of user
export const fetchProductTransactions = "/getUserProductTransactions/";

// Fetch product logs of user
export const fetchProductLogs = "/getUserProductLogs/";

// Fetch theme details
export const fetchThemeDetails = "/admin/profile/";

//Signup customization
export const signupCustomization = "/admin/signUpCustomization/";

// Fetch api wallet credits
export const fetchCredits = "/credits/";

// Fetch product wallet credits
export const fetchProductCredits = "/productCredits/";

// Request demo call
export const oceanDemoQuery = "/bookDemoCall";

// Fetch basic details of a user
export const userDetails = "/user/accountDetails/";

// fetch api prices set for a user
export const getUserPrices = "/admin/getPricesUser/";

// Trubank Upload File API
export const trubankFileUpload= "/emptraTruBank";

// TruBank List Data API 
export const trubanklistdata = "/truBankList/";

//  TruBank Data Using RequestId
export const trubankDetailsRequest = "/getEmptraTruBank/";

// Get address name and address of default QR code
export const trulogAddressUrl = "/getAddress/";

// Create Background Check Request
export const BGCRequestUrl = "/bgcClaim";

// Add Business name and address
export const addAddressUrl = "/addressUpdate/";

// Fetch Background Check Request List
export const getBGCRequestListsUrl = "/bgcClaimStatus/";

// Fetch Background Check Request
export const getBGCRequestUrl = "/getBgcClaim/";

// Resend Background Check Request
export const resendBGCRequest = "/resendBgcClaim";

// Submit Uploaded Documents for BGC
export const submitDocumentUpload = "/bgcDocumentShared";

// Decrypt Background Check Code
export const decryptBGCUrl = "/decryptUserId";

// Update Background Check Request
export const updateBGCRequestUrl = "/bgcClaimUpdate";

// Check if the user (by phone) is registered or not
export const checkBgcUser = "/checkMobileV1";

// Send OTP for login
export const sendOTPTruegy = "/login/sendOtpV1";

// Verify OTP for login
export const verifyOTPTruegy = "/login/verifyOtp";

// Registration on truegy server
export const registerUserUrl = "/truBgcRegistration";

// Userid first then company id
export const getVerificationStatus = "/getTruKyc/";

// Add pan details for kyc
export const updatePan = "/truKyc";

// Upload file to S3 bucket
export const uploadFile = "/s3Upload/verification";

// Reset face match in bgc
export const resetFaceMatch = "/reset/FaceComparison";

// Verify otp for aadhaar verification
export const verifyAadhaarOtp = "/bgcAadhaarVerification/submitOtp";

// Send otp for aadhaar verification
export const sendAadhaarOtp = "/bgcAadhaarVerification/sendOtp";

// Add image for face match
export const faceComparison = "/faceComparison";

// Get api list with price
export const fetchApiPricing = "/getApiSendBox/";

// ==> Court Case Start
// Fetch list of normal court case verifications
export const fetchNormalCourtCaseList = "/getCourtCaseV1ByUserId/";

// Fetch details of normal court case verification by request id
export const fetchNormalCourtCaseDetails = "/getCourtCaseV1/";

// Fetch list of immediate court case verifications
export const fetchImmediateCourtCaseList = "/getCourtCaseV2ByUserId/";

// Fetch details of immediate court case verification by request id
export const fetchImmediateCourtCaseDetails = "/getCourtCaseV2/";

// Fetch list of delay court case verifications
export const fetchDelayCourtCaseList = "/getCourtCaseV3ByUserId/";

// Fetch details of delay court case verification by request id
export const fetchDelayCourtCaseDetails = "/getCourtCaseV3/";

// Add new normal case verification
export const normalCaseVerification = "/wlCourtCaseV1";

// Add new immediate case verification
export const immediateCaseVerification = "/wlCourtCaseV2";

// Add new delay case verification
export const delayCaseVerification = "/wlCourtCaseV3";

// Callback url for court case product
export const courtCaseCallback = "/wlCourtCaseV2CallBack";
// ==> Court Case End

// Add Driving License
export const addDrivingLicense = "/bgcDrivingLicense";

// Fetch driving license details
export const getDrivingLicense = "/getBgcDrivingLicense/";

// Remove driving license details
export const removeDrivingLicence = "/removeDrivingLicence/";

// Fetch education details
export const educationHistoryUrl = "/getEducationHistory/";

// Add education details
export const addEducationUrl = "/addEducationHistory/";

// Fetch institute list
export const instituteListUrl = "/insititue/";

// Add institute
export const addInsitituteUrl = "/addInstitute/";

// Fetch experience details
export const employmentHistoryUrl = "/getEmploymentHistory/";

// Fetch vehicle verification details
export const getVehicleVerification = "/getBgcVehicleVerification/";

// add Vehicle Verification
export const vehicleVerification = "/bgcVehicleVerification";

// Remove vehicle details
export const removeVehicleRegistration = "/removeVehicleRegistration/";

// Add experience details
export const addEmploymentUrl = "/addEmploymentHistory/";

// Add hr details for verification
export const initiateHrVerificationUrl = "/employee/initiateHrVerification";

// Update education details
export const updateEducationUrl = "/updateEducationHistory/";

// Remove education details
export const removeEducationUrl = "/removeEducationHistory/";

// Remove experience details with hr details
export const deleteEmploymentHrUrl = "/deleteEmployment/";

// Remove experience details
export const deleteEmploymentUrl = "/removeEmploymentHistory/";

// Update experience details with hr details
export const updateEmploymentUrl = "/updateEmploymentHistory/";

// Get hr verification status
export const hrVerificationStatus = "/hrStatus/";

// Get HR Details
export const gethrDetailsUrl = "/decryptHrCode/";

// Details of truegy user
export const aboutEmployeeUrl = "/getUsersBgc/";

//  HR Details Verification/AddOn
export const hrverificationDetails = "/employee/hrVerification";

// Fetch Address details for verification
export const getAddressDetails = "/addressVerification/";

// Add Address for verification
export const addressVerification = "/addressVerificationClaim";

// fetch voter id details
export const getVoterId = "/getBgcVoterIdSearch/";

// Add voter id details
export const addVoterId = "/bgcVoterIdSearch";

// Remove voter id details
export const removeVoterId = "/removeVoterId/";

// Fetch bank account details
export const bankDetailsUrl = "/verification/getBankDetails/";

// Add bank account details
export const addBankAccount = "/bgcBankAccountVerification";

// Remove bank account details
export const removeBankAccount = "/removeBankAccountVerification/";

// Add bmi details
export const addBmiUrl = "/addBmi/";

// Fetch bmi details
export const bmiUrl = "/getBmi/";

// Remove bmi details
export const removeBmi = "/removeBmi/";

// url for getting EPFO details by providing requestId
export const getEpfoUserDetails = "/getTruKyc/";

// post url for updating user data to get EPFO details
export const postEpfoUserDetails = "/bgcEpfoByMobile";

// Add court case v2 details
export const courtCaseV2 = "/bgcCourtCase/V2";

// Get court case v2 details
export const getCourtCaseV2 = "/getBgcCourtCaseV2/";

// Callback url for court case in bgc
export const bgcCourtCaseCallback = "/bgcCourtCaseV2CallBack";

// Get list of all hr verifications
export const hrVerificationList = "/userHrDetails/";

// Aadhaar Digilocker
export const aadhaarDigilocker = "/whiteLabelBgcAuthorize";

// Get aadhaar details synced with digilocker
export const getEadhaar = "/truKycEadhaarDigilocker";

// Sync aadhaar with digilocker
export const syncDigilockerAadhaar = "/adhaarKycDigilocker";

// =====> BGV verification start

//TruBgv verify drivingLicense
export const drivingLicense = "/truBgv/drivingLicense";

//TruBgv responsesList by userId (Get)
export const truBgvResponses = "/getTruBgvList/";

//TruBgv getTruBgvDetails by userId (Get)
export const getTruBgvRequestId = "/getTruBgvRequestId/";

//TruBgv EPFO Detail Search
export const itrVerification = "/truBgv/form26ExtractionV1";

// =====> BGV verification end

// TruBank manualVerifications Post API 
export const manualverificationTrubank = "/truBankManualVerification";

//Sandbox Count for Dashboard Counter Section
export const countSandbox = "/getCountSandbox/";

//BGC Response for Dashboard Counter Section
export const countBGC = "/getCountBgc/";

//BGV Response Count for Dashboard Counter Section
export const countBGV = "/getCountBgv/";

// Export API transactions
export const exportAPITransactions = "/user/export/transactions/?";

// Export product transactions
export const exportProductTransactions = "/user/export/productTransactions/?";

// Fetch API documentation link given by admin
export const getApiDocumentation = "/user/documentLink/";

// Fetch Sand box status set by admin
export const fetchSandBoxStatus = "/getSendBoxStatus/";

// Fetch product status
export const getProductStatus = "/user/getProductStatus/";

// ========== Forgot password apis start here
// Send email for verification
export const mailSent = "/mailSent/forgotPassword/";

// Decrypt email
export const decryptEmail = "/decryptMailStr/";

// Set New Password
export const setPassword = "/users/setPassword";
// ========== Forgot password apis end here

// Fetch price for all veridications in TruBGV
export const getBgvPricesUser = "/getBgvPricesUser/";

// TruBgc Product price
export const truBgcProductPrice = "/getBgcPricesUser/";

// Court case price
export const courtCasePrice= "/getCourtCasePricesUser/";

// AML price
export const amlPrice = "/getAmlPriceUser/";

// Bank verification price
export const bankVerificationPrice = "/getBankStmtVerifyPriceUser/";

// fetch razorpay key from domain
export const fetchRazorpayKey = "/admin/keys/";

// Generate order for adding balance in API or Product wallet
export const createOrderUrl = "/user/generateOrder";

// Generate signature for verifying transaction
export const generateSignature = "/user/generateSignature";

// Verify signature for adding balance in API wallet
export const verifySignature = "/user/paymentVerification";

// Verify signature for adding balance in Product wallet
export const verifyProductSignature = "/user/productpaymentVerification";

//Edit Profile Details
//Send otp 
export const sendOtpUrl = "/mobile/genrateOtp";

//Verify otp
export const verifyOtpUrl ="/mobile/verifyOtp";

//Edit Name
export const updateName ="/updateDetails"