import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserName } from '../../../Utils/Common';
import api, { getApiDocumentation, fetchSandBoxStatus, getProductStatus } from "../../../Base/OceanConfig";
import { ColorContext } from '../../../Components/ColorContext/ColorContext';
import { useUser } from '../../../Components/FullAccessContext/UserContext';
import styles from './sidebar.module.css';

export default function Menu(props){
	const navigate = useNavigate();
	const location = useLocation().pathname;
	const { css } = useContext(ColorContext);
	const { fullAccess, setShowProduct } = useUser();
    const userName = getUserName();
	const [ productMenu, setProductMenu ] = useState(false);
	const [ courtCase, setCourtCase ] = useState(false);
	const [ truAML, setTruAML ] = useState(false);
	const [ truBGV, setTruBGV ] = useState(false);
	const [ truBank, setTruBank ] = useState(false);
	const [ truBGC, setTruBGC ] = useState(false);
	const [	apiDocumentLink, setApiDocumentLink ] = useState('');
	const [	sandBoxStatus, setSandBoxStatus ] = useState(true);
	const [	productStatus, setProductStatus ] = useState(true);
	const [ logoutModal, setLogoutModal ] = useState(false);
	const [hoveredItem, setHoveredItem] = useState(null);

	const checkUrl = () => {
		if((document.location.pathname === "/court-case-check") || (document.location.pathname === "/court-case-responses") || (document.location.pathname === "/normal-report") ||
		(document.location.pathname === "/immediate-report") || (document.location.pathname === "/delay-report")){
			setProductMenu(true);
			setCourtCase(true);
		} else if((document.location.pathname === "/aml") || (document.location.pathname === "/aml-responses") || (document.location.pathname === "/aml-detail")){
			setProductMenu(true);
			setTruAML(true);
		} else if((document.location.pathname === "/bgv") || (document.location.pathname === "/bgv-responses") || (document.location.pathname === "/bgv-details")){
			setProductMenu(true);
			setTruBGV(true);
		} else if((document.location.pathname === "/trubgv") || (document.location.pathname === "/trubgv-responses") || (document.location.pathname === "/trubgv-detail") || (document.location.pathname === "/trubgv-verification")){
			setProductMenu(true);
			setTruBGV(true);
		} else if((document.location.pathname === "/trubank") || (document.location.pathname === "/trubank-responses") || (document.location.pathname === "/trubank-detail")){
			setProductMenu(true);
			setTruBank(true);
		} else if((document.location.pathname === "/bgc") || (document.location.pathname === "/bgc-responses") || (document.location.pathname === "/bgc-detail")){
			setProductMenu(true);
			setTruBGC(true);
		} else {
			setProductMenu(false);
			setCourtCase(false);
			setTruAML(false);
			setTruBGV(false);
			setTruBank(false);
			setTruBGC(false);
		}
	}

	const apidocumentation = () => {
		api.get(getApiDocumentation + userName)
		.then((res) => {
			if(res.data.code === 200){
                setApiDocumentLink(res.data.result.documentLink)
            } else {
                setApiDocumentLink('')
            }
			sandBoxStatusFunction();
		})
		.catch((error) => {
			console.log(error);
		})
	}

	const sandBoxStatusFunction = () => {
		api.get(fetchSandBoxStatus + userName)
		.then((res) => {
			if(res.data.code === 200){
                setSandBoxStatus(res.data.doesExist.sendBoxStatus);
            } else {
                setSandBoxStatus(true);
            }
			productStatusFunction();
		})
		.catch((error) => {
			console.log(error);
		})
	}

	const productStatusFunction = () => {
		api.get(getProductStatus + userName)
		.then((res) => {
			if(res.data.code === 200){
                setProductStatus(res.data.doesExist.productStatus);
				
				setShowProduct(res.data.doesExist.productStatus)
            } else {
                setProductStatus(true);
				setShowProduct(true);
            }
		})
		.catch((error) => {
			console.log(error);
		})
	}

    useEffect(() => {
		apidocumentation();
        checkUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.location.pathname])

    return (<>
		{logoutModal && <div className="modal-container">
			<div className="modal-background" onClick={() => setLogoutModal(false)}></div>
			<div className="modal-box">
				<div className={styles['logout-modal']}>
					<h4>Logout</h4>
					<h5>Are you sure you want to logout?</h5>
					<div className="modal-action">
						<button className='btn secondary-button' style={css.secondaryButton} onClick={() => setLogoutModal(false)}>Cancel</button>
						<button className='btn primary-button' style={css.primaryButton} onClick={() => navigate('/logout')}>Logout</button>
					</div>
				</div>
			</div>
		</div>}
        {productMenu
			?<div className={styles["sidemenu-container"]}>
				<div className={styles["sidemenu-box"]}>
					<ul className="sidemenu-items">
						<li><a href="/products"><img src="./images/sidemenu/back.svg" alt="icon" /> Back to products</a></li>
					</ul>
				</div>
				<div className={styles["sidemenu-box"]}>
					<h6>Product Menu</h6>
					<ul className="sidemenu-items">
						{courtCase && <li>
							<span style={css.gradientBackground}><img src="./images/sidemenu/products.svg" alt="icon" /> Court case check</span>
							<div>
								<p>
									{
										(location === "/court-case-check")
										?<span><Link style={css.color} to="/court-case-check">Verification</Link></span>
										:<span><Link to="/court-case-check">Verification</Link></span>
									}
									{
										(location === "/court-case-responses" || location === "/normal-report" || location === "/immediate-report" || location === "/delay-report")
										?<span><Link style={css.color} to="/court-case-responses">Responses</Link></span>
										:<span><Link to="/court-case-responses">Responses</Link></span>
									}
								</p>
							</div>
						</li>}
						{truAML && <li>
							<span style={css.gradientBackground}><img src="./images/sidemenu/products.svg" alt="icon" /> TruAML</span>
							<div>
								<p>
									{
										(location === "/aml")
										?<span><Link style={css.color} to="/aml">Verification</Link></span>
										:<span><Link to="/aml">Verification</Link></span>
									}
									{
										(location === "/aml-responses" || location === "/aml-detail")
										?<span><Link style={css.color} to="/aml-responses">Responses</Link></span>
										:<span><Link to="/aml-responses">Responses</Link></span>
									}
								</p>
							</div>
						</li>}
						{truBGV && <li>
							<span style={css.gradientBackground}><img src="./images/sidemenu/products.svg" alt="icon" /> TruBGV</span>
							<div>
								<p>
									{
										(location === "/trubgv")
										?<span><Link style={css.color} to="/trubgv">Verification</Link></span>
										:<span><Link to="/trubgv">Verification</Link></span>
									}
									{
										(location === "/trubgv-responses" || location === "/trubgv-detail")
										?<span><Link style={css.color} to="/trubgv-responses">Responses</Link></span>
										:<span><Link to="/trubgv-responses">Responses</Link></span>
									}
								</p>
							</div>
						</li>}
						{truBank && <li>
							<span style={css.gradientBackground}><img src="./images/sidemenu/products.svg" alt="icon" /> TruBank</span>
							<div>
								<p>
									{
										(location === "/trubank")
										?<span><Link style={css.color} to="/trubank">Verification</Link></span>
										:<span><Link to="/trubank">Verification</Link></span>
									}
									{
										(location === "/trubank-responses" || location === "/trubank-detail")
										?<span><Link style={css.color} to="/trubank-responses">Responses</Link></span>
										:<span><Link to="/trubank-responses">Responses</Link></span>
									}
								</p>
							</div>
						</li>}
						{truBGC && <li>
							<span style={css.gradientBackground}><img src="./images/sidemenu/products.svg" alt="icon" /> TruBGC</span>
							<div>
								<p>
									{
										(location === "/bgc")
										?<span><Link style={css.color} to="/bgc">Verification</Link></span>
										:<span><Link to="/bgc">Verification</Link></span>
									}
									{
										(location === "/bgc-responses" || location === "/bgc-detail")
										?<span><Link style={css.color} to="/bgc-responses">Responses</Link></span>
										:<span><Link to="/bgc-responses">Responses</Link></span>
									}
								</p>
							</div>
						</li>}
					</ul>
				</div>
			</div>
			:<div className={styles["sidemenu-container"]}>
				<div className={styles["sidemenu-box"]}>
					<ul className="sidemenu-items">
						{
							location === "/dashboard"
							?<li><Link to="/dashboard" style={css.gradientBackground}><img src="./images/sidemenu/dashboard.svg" alt="icon" /> Dashboard</Link></li>
							:<li onMouseEnter={() => setHoveredItem('Dashboard')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Dashboard'?`${css.color.color}10`:''}}><Link to="/dashboard"><img src="./images/sidemenu/dashboard.svg" alt="icon" /> Dashboard</Link></li>
						}
					</ul>
				</div>
				<div className={styles["sidemenu-box"]}>
					<h6>APIs Menu</h6>
					<ul className="sidemenu-items">
						{fullAccess &&<>
							{apiDocumentLink
								?<li onMouseEnter={() => setHoveredItem('Api-Documentaion')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Api-Documentaion'?`${css.color.color}10`:''}}><a target="_blank" rel="noreferrer" href={apiDocumentLink}><img src="./images/sidemenu/documentation.svg" alt="documentation" /> API Documentation</a></li>
								:<li onMouseEnter={() => setHoveredItem('Api-Documentaion')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Api-Documentaion'?`${css.color.color}10`:''}}><a target="_blank" rel="noreferrer" href="https://documenter.getpostman.com/view/15230345/2s9YJf12Kz#2867f615-7bfd-4e17-8f83-11a003e99830"><img src="./images/sidemenu/documentation.svg" alt="documentation" /> API Documentation</a></li>
							}
							{
								location === "/api-keys"
								?<li><Link to="/api-keys" style={css.gradientBackground}><img src="./images/sidemenu/api-keys.svg" alt="icon" /> API Keys</Link></li>
								:<li onMouseEnter={() => setHoveredItem('Api-keys')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Api-keys'?`${css.color.color}10`:''}}><Link to="/api-keys"><img src="./images/sidemenu/api-keys.svg" alt="icon" /> API Keys</Link></li>
							}
						</>}
						{/* {
							location === "/api-pricing"
							?<li><Link to="/api-pricing" style={css.gradientBackground}><img src="./images/sidemenu/api-pricing.svg" alt="icon" /> API Pricing</Link></li>
							:<li><Link to="/api-pricing"><img src="./images/sidemenu/api-pricing.svg" alt="icon" /> API Pricing</Link></li>
						} */}
						{
							location === "/api-usage"
							?<li><Link to="/api-usage" style={css.gradientBackground}><img src="./images/sidemenu/api-usage.svg" alt="icon" /> API Usage</Link></li>
							:<li onMouseEnter={() => setHoveredItem('Api-usage')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Api-usage'?`${css.color.color}10`:''}}><Link to="/api-usage"><img src="./images/sidemenu/api-usage.svg" alt="icon" /> API Usage</Link></li>
						}
						{sandBoxStatus && <li onMouseEnter={() => setHoveredItem('Sandbox')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Sandbox'?`${css.color.color}10`:''}}><Link to="/test-apis"><img src="images/sidemenu/testapis.png" alt="icon" /> API’s </Link></li>}
					</ul>
				</div>
				{productStatus && <div className={styles["sidemenu-box"]}>
					<h6>Product Menu</h6>
					<ul className="sidemenu-items">
						{
							location === "/products"
							?<li><Link to="/products" style={css.gradientBackground}><img src="./images/sidemenu/products.svg" alt="icon" /> Products</Link></li>
							:<li onMouseEnter={() => setHoveredItem('Products')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Products'?`${css.color.color}10`:''}}><Link to="/products"><img src="./images/sidemenu/products.svg" alt="icon" /> Products</Link></li>
						}
					</ul>
				</div>}
				{props.userId && <div className={styles["sidemenu-box"]}>
					<h6>Support</h6>
					<ul className="sidemenu-items">
						{
							location === "/my-account"
							?<li><Link to="/my-account" style={css.gradientBackground}><img src="./images/sidemenu/my-account.svg" alt="icon" /> My Account</Link></li>
							:<li onMouseEnter={() => setHoveredItem('My-account')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='My-account'?`${css.color.color}10`:''}}><Link to="/my-account"><img src="./images/sidemenu/my-account.svg" alt="icon" /> My Account</Link></li>
						}
						{
							location === "/whitelist-ip"
							?<li><Link to="/whitelist-ip" style={css.gradientBackground}><img src="images/sidemenu/whitelist-ip.svg" alt="icon" /> Whitelist IP </Link></li>
							:<li onMouseEnter={() => setHoveredItem('Whitelist-ip')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Whitelist-ip'?`${css.color.color}10`:''}}><Link to="/whitelist-ip"><img src="images/sidemenu/whitelist-ip.svg" alt="icon" /> Whitelist IP </Link></li>
						}
						{/* <li><NavLink to="/logout"><img src="./images/sidemenu/logout.svg" alt="icon" /> Logout</NavLink></li> */}
						<li onMouseEnter={() => setHoveredItem('Logout')} onMouseLeave={() => setHoveredItem(null)} style={{background:hoveredItem==='Logout'?`${css.color.color}10`:''}}><span onClick={() => setLogoutModal(true)}><img src="./images/sidemenu/logout.svg" alt="icon" /> Logout</span></li>
					</ul>
				</div>}
			</div>
		}
	</>);
}