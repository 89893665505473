import React, { useState, useContext } from 'react';
import SignIn from './Components/SignIn';
import SignUp from './Components/SignUp';
import styles from './login-modal.module.css';
import {ColorContext} from '../../../Components/ColorContext/ColorContext';

export default function LoginModal(props){
    const [ registration, setRegistration ] = useState(false);
    
    const { logo } = useContext(ColorContext);

    const toggleRegistration = (value) => {
        setRegistration(value);
    }

    return (<>
        <div className={styles["registration-modal-container"]}>
            <div className={styles["registration-modal-background"]} onClick={() => props.closeModal()}></div>
            <div className={styles["registration-modal-box"]}>
                <div className={styles["registration-modal-left-side"]} style={{backgroundImage:'url(../images/contact-us-background.svg)'}}>
                    <div>
                        <img src={logo} alt="" />
                        <h4>Welcome </h4>
                        <p>Sign in to get your own customize white-label product</p>
                    </div>
                    <img src="./images/contact-us.svg" alt="" />
                </div>
                <div className={styles["registration-modal-right-side"]}>
                    <h3>{registration ? "Sign Up" : "Sign In" } <img src="./images/times-circle-color.svg" alt="times-circle" onClick={() => props.closeModal()} /></h3>
                    {registration
                        ?<SignUp toggleRegistration={toggleRegistration} redirectPath={props.redirectPath} />
                        :<SignIn toggleRegistration={toggleRegistration} redirectPath={props.redirectPath} />
                    }
                </div>
            </div>
        </div>
    </>);
};