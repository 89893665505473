import React, { useState } from 'react';
import Menu from './Menu';
import styles from './sidebar.module.css';

export default function Sidebar(props){
	const userId = props.userId;
	const logo = props.logo;
	const [ mobileNav, setMobileNav ] = useState(false);
	
    return (<>
        <div className={styles["mobile-nav"]}>
			<div className={styles["mobile-top-nav"]}>
				<img src="images/bars.png" alt="bars" onClick={() => setMobileNav(true)} />
				<div className={styles['dashboard-image']}>
            		<img src={logo} alt="" />
				</div>
			</div>
			{mobileNav && <div className={styles["mobile-side-nav"]}>
				<div className={styles["mobile-sidemenu-background"]}>
					<img src="images/times-light.png" alt="times-light" onClick={() => setMobileNav(false)} />
				</div>
				<div className={styles["mobile-sidemenu"]}>
					<div className={styles['dashboard-image']}>
						<img src={logo} alt="" />
					</div>
					<Menu userId={userId} />
				</div>
			</div>}
		</div>
        <div className={styles["invincible-ocean-sidemenu"]}>
			<div className={styles['dashboard-image']}>
				<img src={logo} alt="" />
			</div>
			<Menu userId={userId} />
		</div>
	</>);
}