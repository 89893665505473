import React, { useContext } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import { ColorContext } from '../../../Components/ColorContext/ColorContext';
import { getUser } from '../../../Utils/Common';
import styles from './layout.module.css';
import { useUser } from '../../../Components/FullAccessContext/UserContext';
import Loading from '../../../Components/Loading/Loading';

export default function Layout({ children}) {
    var userId = getUser();
    const { loading } = useContext(ColorContext);
    const { domain } = useContext(ColorContext);
    const { logo } = useContext(ColorContext);
    const { css } = useContext(ColorContext);
    const { showProduct } = useUser();

    return (<>
    {
        loading
        ?<div className="loading-screen">loading ...</div>
        :<div className={styles['main-container']}>
            <Sidebar userId={userId} logo={logo} />
            <div>{
                showProduct === null? <Loading/>:<>
                <Navbar userId={userId} css={css} domain={domain} logo={logo} />
                <main>{children}</main>
                </>}
            </div>
        </div>
    }
    </>);
}
